// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pk2-angular-primary: mat.define-palette(mat.$indigo-palette);
$pk2-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pk2-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pk2-angular-theme: mat.define-light-theme((
  color: (
    primary: $pk2-angular-primary,
    accent: $pk2-angular-accent,
    warn: $pk2-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pk2-angular-theme);

/*!

 =========================================================
 * Paper Kit 2 Angular - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2-angular
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/timcreative/paper-kit/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

@import "paper-kit/variables";
@import "paper-kit/mixins";

@import "paper-kit/typography";

// Core CSS
@import "paper-kit/misc";
@import "paper-kit/buttons";
@import "paper-kit/inputs";
@import "paper-kit/progress-bars";
@import "paper-kit/sliders";
@import "paper-kit/alerts";
@import "paper-kit/labels";
@import "paper-kit/tooltips-and-popovers";
@import "paper-kit/sections";
@import "paper-kit/checkbox-radio";
@import "paper-kit/navbars";
@import "paper-kit/images";
@import "paper-kit/cards";
@import "paper-kit/footers";


// Bootstrap Switch
@import "paper-kit/bootstrap-switch";

// Fancy Stuff
@import "paper-kit/dropdown";
//
// icons
@import "paper-kit/icons";
//
@import "paper-kit/tabs-navs-pagination";
@import "paper-kit/collapse";
@import "paper-kit/carousel";
@import "paper-kit/modal";
//
@import "paper-kit/responsive";
@import "paper-kit/plugins/datetimepicker";
@import "paper-kit/social-buttons";
@import "paper-kit/examples";
